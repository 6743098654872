import { IMAGES_APP_PRODUCTIVITY } from "../../../../constants"

export const IMAGES = IMAGES_APP_PRODUCTIVITY


export const FEATURES = [
  {
    id: 'manage_tasks',
    img: IMAGES.tasks
  },
  {
    id: 'manage_habits',
    img: IMAGES.habits
  },
  {
    id: 'manage_goals',
    img: IMAGES.goals
  },
  {
    id: 'statistics',
    img: IMAGES.statistics
  },
  {
    id: 'reports',
    img: IMAGES.reports,
  },
  {
    id: 'web',
    img: IMAGES.web,
  },
  {
    id: 'modeview',
    img: IMAGES.modeview,
  },
  {
    id: 'tools',
    img: IMAGES.tools,
  },
  {
    id: 'gamification',
    img: IMAGES.gamification,
  },
]