import styled from 'styled-components';

export const SectionContainer = styled.header`
  display:flex;
  flex:1;
  align-items:center;
  margin-top:50px;
  margin-bottom:50px;
  .border-section{
    display:flex;
    flex:1;
    border: 0.5px solid ${({color,theme})=> color ?? theme.colors.primary};
    margin: 0 5%;
  }
  @media (max-width: 700px) {
    margin-top:2.4rem;
    margin-bottom:0;
  }
`;

