import React from 'react';
import { SectionContainer } from './styles';
import { TitleSection } from '../atomics';

function Section({id,title,children, color}) {
  return (
    <>
      <SectionContainer id={id} color={color}>
        <div className="border-section"/>
          <TitleSection color={color}>{title}</TitleSection>
        <div className="border-section"/>
      </SectionContainer>
    {children}
    </>
  )
}

export default Section;